import React from 'react';
import './TopicSelector.css';

const topics = ['时事', '金融', '八卦', '针灸', '天气', '家常菜'];

const TopicSelector = ({ selectedTopic, setSelectedTopic }) => {
  return (
    <div className="topic-selector">
      {topics.map((topic) => (
        <button
          key={topic}
          className={selectedTopic === topic ? 'active' : ''}
          onClick={() => setSelectedTopic(topic)}
        >
          {topic}
        </button>
      ))}
    </div>
  );
};

export default TopicSelector;
