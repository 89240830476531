import React from 'react';
import './App.css';

// Imports the Amplify library from 'aws-amplify' package. This is used to configure your app to interact with AWS services.
import {Amplify} from 'aws-amplify';

// Imports the Authenticator and withAuthenticator components from '@aws-amplify/ui-react'.
// Authenticator is a React component that provides a ready-to-use sign-in and sign-up UI.
// withAuthenticator is a higher-order component that wraps your app component to enforce authentication.
import { Authenticator, withAuthenticator } from '@aws-amplify/ui-react';

// Imports the default styles for the Amplify UI components. This line ensures that the authenticator looks nice out of the box.
import '@aws-amplify/ui-react/styles.css';

// Imports the awsExports configuration file generated by the Amplify CLI. This file contains the AWS service configurations (like Cognito, AppSync, etc.) specific to your project.
import awsExports from './aws-exports';

import { useState } from 'react';

import Header from './components/Header';
import NewsList from './components/NewsList';
import TopicSelector from './components/TopicSelector';

// Configures the Amplify library with the settings from aws-exports.js, which includes all the AWS service configurations for this project.
Amplify.configure(awsExports);

const newsData = {
  时事: [
    { title: "时事新闻1", description: "时事新闻1的描述" },
    { title: "时事新闻2", description: "时事新闻2的描述" },
  ],
  金融: [
    { title: "金融新闻1", description: "金融新闻1的描述" },
    { title: "金融新闻2", description: "金融新闻2的描述" },
  ],
  八卦: [
    { title: "八卦新闻1", description: "八卦新闻1的描述" },
    { title: "八卦新闻2", description: "八卦新闻2的描述" },
  ],
  针灸: [
    { title: "针灸新闻1", description: "针灸新闻1的描述" },
    { title: "针灸新闻2", description: "针灸新闻2的描述" },
  ],
  天气: [
    { title: "天气新闻1", description: "天气新闻1的描述" },
    { title: "天气新闻2", description: "天气新闻2的描述" },
  ],
  家常菜: [
    { title: "家常菜新闻1", description: "家常菜新闻1的描述" },
    { title: "家常菜新闻2", description: "家常菜新闻2的描述" },
  ],
};

function App() {
  const [selectedTopic, setSelectedTopic] = useState('时事');


  return (
    <div className="App">
      <Authenticator>
        {({ signOut }) => (
          <main>
            <header className='App-header'>
            <Header />
            <TopicSelector selectedTopic={selectedTopic} setSelectedTopic={setSelectedTopic} />
            <NewsList news={newsData[selectedTopic]} />
              <button 
                onClick={signOut} 
                style={{ 
                  margin: '20px', 
                  fontSize: '0.8rem', 
                  padding: '5px 10px', 
                  marginTop: '20px'
                }}
              >
                Sign Out
              </button>
            </header>
          </main>
        )}
      </Authenticator>
    </div>
  );
}

export default withAuthenticator(App);
