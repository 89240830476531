import React from 'react';
import './NewsCard.css';

const NewsCard = ({ newsItem }) => {
  return (
    <div className="news-card">
      <h2>{newsItem.title}</h2>
      <p>{newsItem.description}</p>
    </div>
  );
};

export default NewsCard;